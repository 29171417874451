import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentFormat } from '../enums/document-format.enum';
import { DocumentType } from '../enums/document-type.enum';
import { ParentType } from '../enums/parent-type.enum';

export class EDMDocument {
  // get
  id: string;
  name: string;
  originalName: string;
  fileType: DocumentType;
  format: DocumentFormat;
  content: Float32Array;
  documentDate: Date;
  tags: string[];

  // post
  file: File;
  parentId: string;
  parentType: ParentType;

  tmpFilePath: string;
  fileLoaded: BehaviorSubject<string> = new BehaviorSubject<string>('');

  deleted: boolean;

  constructor(data?: any, file$: Observable<Blob> = null) {
    if (data) {
      this.id = data?.id;
      this.name = data?.name;
      this.originalName = data?.originalName;
      this.fileType = data?.fileType;
      this.format = data?.format;
      this.documentDate = data?.documentDate ? new Date(data.documentDate) : null;
      this.tags = data?.tags || [];
    }

    if (file$) {
      file$.subscribe((blob: Blob) => {
        this.file = new File([blob], this.originalName, { type: this.getMimeType(this.format), lastModified: Date.now() });
        if (this.format === DocumentFormat.PDF) {
          const tmpBlob = new Blob([blob], { type: 'application/pdf' });
          if (tmpBlob) {
            const reader = new FileReader();
            reader.readAsDataURL(tmpBlob);
            reader.onloadend = () => {
              this.setTmpPath(reader);
              this.fileLoaded.next(reader.result as string);
            };
          }
        } else {
          // file.file = new File([mp3Blob], 'audio.mp3', { type: 'audio/mpeg' });
          this.tmpFilePath = this.file ? URL.createObjectURL(this.file) : null;
          this.fileLoaded.next(this.tmpFilePath);
        }
      });
    }
  }

  setTmpPath(reader: FileReader) {
    this.tmpFilePath = reader.result as string;
  }

  getMimeType(format: DocumentFormat): string {
    switch (format) {
      case DocumentFormat.JPG:
      case DocumentFormat.JPEG:
        return 'image/jpeg';
      case DocumentFormat.PNG:
        return 'image/png';
      case DocumentFormat.PDF:
        return 'application/pdf';
      case DocumentFormat.MP4:
        return 'video/mp4';
      case DocumentFormat.MP3:
        return 'audio/mpeg';
      case DocumentFormat.WEBP:
        return 'image/webp';
      case DocumentFormat.DOCX:
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case DocumentFormat.WAV:
        return 'audio/wav';
      case DocumentFormat.CSV:
        return 'text/csv';
      case DocumentFormat.XLSX:
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case DocumentFormat.XLS:
        return 'application/vnd.ms-excel';
      case DocumentFormat.MOV:
        return 'video/quicktime';
      case DocumentFormat.WEBM:
        return 'video/webm';
      case DocumentFormat.WEBM_OPUS:
        return 'audio/webm';
      case DocumentFormat.None:
      default:
        return 'application/octet-stream';
    }
  }
}
