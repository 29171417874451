export enum DocumentFormat {
    None = 0,
    JPG = 1,
    PNG = 2,
    PDF = 3,
    MP4 = 4,
    MP3 = 5,
    WEBP = 6,
    DOCX = 7,
    WAV = 8,
    CSV = 9,
    XLSX = 10,
    XLS = 11,
    MOV = 12,
    JPEG = 13,
    WEBM = 14,
    WEBM_OPUS = 15,
}
