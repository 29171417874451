import { AuthentificationService, HttpClientService } from '@amaris/lib-highway/services';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable, catchError, map, of,
  retry,
} from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { DataStoreService } from '../../data-store/data-store.service';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { EDMDocument } from '../../../models/edm-document.model';
import { ApiResponse } from '../../../models/api-response.model';
import { DocumentFormat } from '../../../enums/document-format.enum';
import { environment } from '../../../../environments/environment';
import { ParentType } from '../../../enums/parent-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  constructor(
    private readonly authentificationService: AuthentificationService,
    private readonly httpClientService: HttpClientService,
    private readonly toastrService: ToastrService,
    private readonly dataStoreService: DataStoreService,
  ) { }

  downloadDocument(file: EDMDocument) {
    const link = document.createElement('a');
    link.download = file.originalName;
    link.href = file.tmpFilePath;
    link.click();
  }

  createDocument(document: EDMDocument): Observable<string> {
    const data = new FormData();
    data.append('file', document.file, document.file.name);
    if (document.name) {
      data.append('Name', document.name);
    }
    data.append('ParentId', document.parentId);
    data.append('ParentType', document.parentType.toString());
    if (document.documentDate) {
      data.append('DocumentDate', document.documentDate.toISOString());
    }
    if (document.tags?.length) {
      data.append('Tags', document.tags.join(','));
    }

    const options = {
      url: `${environment.apiUrl + ApiEndpoints.Documents}`,
      body: data,
      headers: new HttpHeaders(
        {
          Authorization: `Bearer ${this.authentificationService.getToken().token}`,
        },
      ),
    };

    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      map((response: ApiResponse<string>) => response.data),
      catchError((error) => {
        this.toastrService.error(`Erreur lors de la création du document ${document.originalName}`);
        return of(null);
      }),
    );
  }

  downloadById(documentId: string): Observable<Blob> {
    const options = {
      url: `${environment.apiUrl + ApiEndpoints.Documents}/${documentId}/download`,
      withCredentials: true,
      responseType: 'blob',
    };

    return this.httpClientService.get<any>(options).pipe(
      map((response: Blob) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        return blob;
      }),
    );
  }

  getById(documentId: string): Observable<EDMDocument> {
    const options = {
      url: `${environment.apiUrl + ApiEndpoints.Documents}/${documentId}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<EDMDocument>>(options).pipe(
      map((response: ApiResponse<EDMDocument>) => new EDMDocument(response.data, this.downloadById(response.data.id))),
    );
  }

  getAllByParentid(
    parentId: string,
    format: DocumentFormat = null,
    type: DocumentType = null,
    parentType: ParentType = null,
  ): Observable<EDMDocument[]> {
    let url = `${environment.apiUrl + ApiEndpoints.Documents}?parentId=${parentId}`;
    if (format) {
      url += `&format=${format}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (parentType) {
      url += `&parentType=${parentType}`;
    }
    const options = {
      url,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<EDMDocument[]>>(options).pipe(
      map((response: ApiResponse<EDMDocument[]>) => {
        if (response.data) {
          return response.data.map((document) => new EDMDocument(document, this.downloadById(document.id)));
        }
        return [];
      }),
      catchError((error) => of([])),
    );
  }

  getInvoiceByParentId(
    parentId: string,
  ): Observable<EDMDocument[]> {
    const url = `${environment.apiUrl + ApiEndpoints.Documents}?parentId=${parentId}&parentType=${ParentType.Invoice}`;
    const options = {
      url,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<EDMDocument[]>>(options).pipe(
      map((response: ApiResponse<EDMDocument[]>) => {
        if (response.data) {
          return response.data.map((document) => new EDMDocument(document, this.downloadById(document.id)));
        }
        return [];
      }),
      retry({ count: 5, delay: 1000 }),
      catchError((error) => of([])),
    );
  }

  deleteDocument(document: EDMDocument): Observable<string> {
    const options = {
      url: `${environment.apiUrl + ApiEndpoints.Documents}/${document.id}`,
      withCredentials: true,
    };

    return this.httpClientService.delete<ApiResponse<string>>(options).pipe(
      map((response: ApiResponse<string>) => response.data),
      catchError((error) => {
        this.toastrService.error(`Erreur lors de la suppression du document ${document.originalName}`);
        return of(null);
      }),
    );
  }
}
